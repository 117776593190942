<!--
 * @Description: 新增耗材自动出库配置
 * @Author: ChenXueLin
 * @Date: 2021-10-19 14:46:30
 * @LastEditTime: 2022-07-01 09:27:33
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    :title="editType == 2 ? '编辑' : '新增'"
    :visible="addVisible"
    width="700px"
    :close-on-click-modal="false"
    v-loading="dialogLoading"
    :element-loading-background="loadingBackground"
    :before-close="handleClose"
    custom-class="install-scene-dialog"
  >
    <el-form
      label-width="120px"
      ref="addForm"
      :model="addForm"
      :rules="addFormRules"
    >
      <el-form-item label="工单类型" prop="workOrderTypeList">
        <e6-vr-select
          v-model="addForm.workOrderTypeList"
          :data="consumeMaterialWorkTypeList"
          placeholder="工单类型"
          title="工单类型"
          clearable
          :props="{
            id: 'codeValue',
            label: 'codeName'
          }"
          multiple
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="是否启用" prop="status">
        <el-switch
          v-model="addForm.status"
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
      </el-form-item>
    </el-form>
    <div>
      <!-- 工单类型+具体耗材 -->
      <el-table
        border
        :data="tableData"
        highlight-current-row
        ref="selectTable"
      >
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :align="column.align"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <div v-if="column.fieldName === 'bizNoList	 '">
              <e6-vr-select
                v-model="row.bizNoList"
                :data="consumeMasterTypeList"
                placeholder="主机类型"
                title="主机类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                multiple
              ></e6-vr-select>
            </div>
            <div v-else-if="column.fieldName === 'materialNo'">
              <remote-search
                v-model="row.materialNo"
                :remote="true"
                clearable
                placeholder="耗材名称"
                title="耗材名称"
                :searchData="{
                  materialTypes: [408702, 408703],
                  pageIndex: 1,
                  pageSize: 200
                }"
                @change="
                  (val, node) => {
                    changeMaterialNo(val, node, row);
                  }
                "
                :defaultValue="
                  editType == 1
                    ? {}
                    : {
                        materialName: row.materialNoName,
                        materialNo: row.materialNo
                      }
                "
                :showInit="editType == 1 ? true : false"
              ></remote-search>
            </div>
            <div v-else-if="column.fieldName === 'amount'">
              <el-input v-model="row.amount"></el-input>
            </div>
            <span v-else>{{ row[column.fieldName] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          show-overflow-tooltip
          align="center"
          header-align="center"
          min-width="90"
        >
          <template slot-scope="{ row, $index }">
            <i
              class="e6-icon-add_line1"
              title="新增"
              @click="handleAdd(row, $index)"
            ></i>
            <i
              class="e6-icon-clear_fill"
              title="删除"
              @click="deleteColumn($index, row)"
            ></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import {
  addManualConsumeMaterial,
  updateManualConsumeMaterial,
  getConsumeMaterialDetail
} from "@/api";
export default {
  name: "add",
  components: {},
  data() {
    return {
      dialogLoading: false,
      addForm: {
        consumeType: "",
        status: 1,
        workOrderTypeList: []
      },
      addFormRules: {
        consumeType: [
          {
            required: true,
            message: "请选择单据类型",
            trigger: ["blur", "change"]
          }
        ],
        workOrderTypeList: [
          {
            required: true,
            message: "请选择工单类型",
            trigger: ["blur", "change"]
          }
        ]
      },
      columnData: [
        {
          fieldName: "bizNoList	 ",
          fieldLabel: "主机类型",
          width: 200,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialNo",
          fieldLabel: "耗材名称",
          width: 200,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialNoStr",
          fieldLabel: "耗材编码",
          width: 100,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "amount",
          fieldLabel: "上报上限",
          width: 100,
          fixed: false,
          align: "center"
        }
      ],
      tableData: [
        {
          materialNo: "",
          materialNoStr: "",
          amount: "",
          bizNoList: []
        }
      ]
    };
  },
  props: [
    "addVisible",
    "consumeMaterialWorkTypeList",
    "consumeMasterTypeList",
    "detailInfo",
    "editType"
  ],
  mixins: [base],
  computed: {},
  watch: {
    addVisible: {
      immediate: true,
      handler(val) {
        if (val && this.editType == 2) {
          let {
            consumeType,
            workOrderTypeList,

            status,
            consumeId
          } = this.detailInfo;
          let TypeList = workOrderTypeList.map(item => {
            item = item + "";
            return item;
          });
          this.addForm.consumeId = consumeId;
          this.addForm.consumeType = consumeType.toString();
          this.addForm.workOrderTypeList = TypeList;
          this.addForm.status = status;
          this.getDetail();
        }
      }
    }
  },
  created() {},
  methods: {
    async getDetail() {
      try {
        this.dialogLoading = true;
        let res = await getConsumeMaterialDetail({
          id: this.detailInfo.consumeId
        });
        if (res.code == "OK") {
          let materialVOList = res.data.materialVOList;
          materialVOList.map(item => {
            item.materialNoStr = item.materialNo;
          });
          this.tableData = materialVOList;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    changeMaterialNo(val, node, row) {
      row.materialNoStr = node.materialNo;
    },
    changeType() {
      this.tableData = [
        {
          materialNo: "",
          materialNoStr: "",
          amount: "",
          bizNoList: []
        }
      ];
    },
    //点击确定
    confirm() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          let tableData = this.tableData;
          if (tableData.some(item => !item.bizNoList.length)) {
            this.$message.warning("请检查主机类型是否全部选择");
            return;
          }
          if (tableData.some(item => !item.materialNo)) {
            this.$message.warning("请检查耗材名称是否全部选择");
            return;
          }
          if (tableData.some(item => !item.amount)) {
            this.$message.warning("请检查上报上限是否全部输入");
            return;
          }
          if (this.editType == 2) {
            this.updateManualConsumeMaterialReq();
          } else {
            this.addManualConsumeMaterialReq();
          }
        }
      });
    },
    //新增
    async addManualConsumeMaterialReq() {
      try {
        this.dialogLoading = true;
        let res = await addManualConsumeMaterial({
          ...this.addForm,
          materialVOList: this.tableData
        });
        if (res.code == "OK") {
          this.$message.success("添加成功");

          this.$emit("refresh");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.addForm = {
        consumeType: "",
        consumeId: "",
        status: 1,
        workOrderTypeList: []
      };
      this.tableData = [
        {
          materialNo: "",
          materialNoStr: "",
          amount: "",
          bizNoList: []
        }
      ];
      this.$emit("close", "addVisible");
    },
    //编辑
    async updateManualConsumeMaterialReq() {
      try {
        this.dialogLoading = true;
        let res = await updateManualConsumeMaterial({
          ...this.addForm,
          materialVOList: this.tableData
        });
        if (res.code == "OK") {
          this.$message.success("编辑成功");
          this.$emit("refresh");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //点击添加
    handleAdd() {
      let tableData = this.tableData;
      tableData.push({
        materialNo: "",
        materialNoStr: "",
        amount: "",
        bizNoList: []
      });
      this.tableData = tableData;
    },
    //删除单行
    deleteColumn(index) {
      let tableData = _.cloneDeep(this.tableData);
      tableData.splice(index, 1);
      this.tableData = tableData;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.install-scene-dialog {
  .el-dialog__body {
    padding-top: 20px;
    [class^="e6-icon"] {
      height: 28px;
      color: #46bfea;
      line-height: 28px;
      font-style: normal;
      text-align: center;
      padding: 0 10px;
      cursor: pointer;
      border-radius: 3px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      padding: 0;
      font-size: 20px;
    }
  }
}
</style>
